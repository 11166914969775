/* Generated by Font Squirrel (http://www.fontsquirrel.com) on October 27, 2015 */



@font-face {
    font-family: 'Share Tech Mono';
    src: url('sharetechmono-regular-webfont.eot');
    src: url('sharetechmono-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('sharetechmono-regular-webfont.woff2') format('woff2'),
         url('sharetechmono-regular-webfont.woff') format('woff'),
         url('sharetechmono-regular-webfont.ttf') format('truetype'),
         url('sharetechmono-regular-webfont.svg#share_tech_monoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}